import firebase from "firebase";

// Initialize Firebase
const config = {
  databaseURL: "https://casosilaprazolchinoinmg.firebaseio.com",
  apiKey: "AIzaSyC_mlFCPKGH-uIT5EIaNQx1S72GXXzyHQI",
  authDomain: "casosilaprazolchinoinmg.firebaseapp.com",
  projectId: "casosilaprazolchinoinmg",
  storageBucket: "casosilaprazolchinoinmg.appspot.com",
  messagingSenderId: "1059965273745",
  appId: "1:1059965273745:web:aacb3f7c7ee31720142130",
  measurementId: "G-EZHVE8D1MW"
};
firebase.initializeApp(config);
firebase.auth().languageCode = 'es'
const auth = firebase.auth();
const functions = firebase.functions();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const storage = firebase.storage();

const database = firebase.firestore();

export {
  database,
  storage,
  auth,
  functions,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};
